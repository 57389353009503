import React, { useEffect, useState } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import {
  List,
  ListMode,
  StandardListItem,
  Button,
  ButtonDesign,
  Title,
  TitleLevel,
  Ui5CustomEvent,
  ListDomRef,
} from '@ui5/webcomponents-react';
import { ListItemClickEventDetail } from '@ui5/webcomponents/dist/List';

import { closeShellDialog } from 'src/common/plugins/dialog.plugin';
import { setDocumentLang, getThemeById } from 'src/common/Utils';
import { MicroFrontendProps, User, UserProfileState, UserName } from 'src/types';

import {
  useTranslation,
  Spinner,
  getCurrentLanguage,
  setLanguage,
  configManagerSetLanguage,
  MessageToast,
} from './eureka';
import { fetchBasicSetup, fetchLanguages, fetchMe, patchMe } from './axios';
import LanguageAndRegion from './LanguageAndRegion';
import UserAppearance from './UserAppearance';
import { TimeFormatOptions, TimeFormatTexts, TimeFormatTextsDefualts } from './options';

const { getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;
const { getFeatureToggle } = eurekaMgrs.ConfigManager;

const initialData = {
  userName: {},
  pictureId: '',
  email: '',
  phone: '',
  language: '',
  dateFormat: '',
  timeFormat: '',
  userUpdateState: 1,
  profileTimeZone: '',
  s4ExternalUserId: '',
  familyNameFirst: false,
  avatarIcon: {},
};

const UserProfileLanguageAndRegion = 'user-profile_language_region';
const UserProfileAppearance = 'user-profile_appearance';
const borderStyle = '1px solid #e8e8e8';

/* istanbul ignore next */
const notifyOthers = (user: User, languageChanged: boolean, themeChanged: boolean) => {
  try {
    if (languageChanged) {
      // tell other mfes, language is changed, only update translations,
      // no reload in this case
      window.eventBus?.emit('i18n-update', 'user language', user?.language || 'en-US');
      setDocumentLang(document, user?.language || 'en-US');
    }
    if (themeChanged) {
      // tell other mfes, theme is changed
      // no reload in this case
      const themeId = user?.themeId || 'sap_horizon';
      window.eventBus?.emit('appearance-update', 'user theme', { themeChanged: true, themeId });
    }
    // tell other mfes, user profile is changed, mfe reloads
    window.eventBus?.emit('configuration-updated', 'user profile', {
      key: 'userProfile',
      data: user,
    });
  } catch (e) {
    console.log(e);
  }
};

/* istanbul ignore next */
const compareData = (data, originalData) => {
  const differ: Record<string, any> = {};
  let languageChanged = false;
  let themeChanged = false;
  Object.keys(data).forEach((key) => {
    if (originalData[key] !== data[key]) {
      differ[key] = data[key];
      if (key === 'language') {
        languageChanged = true;
      }
      if (key === 'themeId') {
        themeChanged = true;
      }
    }
  });
  if (Object.keys(differ).length > 0) {
    let current = parseInt(data.userUpdateState);
    current = isNaN(current) ? 1 : current;
    differ.userUpdateState = current + 1;
  }
  return { differ, languageChanged, themeChanged };
};

type Props = Pick<MicroFrontendProps, 'config' | 'eventBus'>;

const UserProfile: React.FC<Props> = ({ config, eventBus }) => {
  const { t } = useTranslation('shell');
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({ ...initialData });
  const [originalData, setOriginalData] = useState({ ...initialData });
  const [languages, setLanguages] = useState([]);
  const [timeFormatIndex, setTimeFormatIndex] = useState(0);
  const [currentItem, setCurrentItem] = useState(UserProfileAppearance);

  const onDataPropChange = (value: string, propName: string) => {
    if (propName.indexOf('.') > 0) {
      const [objName, subPropName] = propName.split('.');
      setData({
        ...data,
        [objName]: {
          ...data[objName],
          [subPropName]: value,
        },
      });
    } else {
      setData({
        ...data,
        [propName]: value,
      });
    }
  };

  const handleSave = () => {
    const { differ, languageChanged, themeChanged } = compareData(data, originalData);
    setLoading(true);
    return patchMe({ ...differ })
      .then((result) => {
        const user = result.data;
        setOriginalData({ ...user });
        setData({ ...user });
        notifyOthers(user, languageChanged, themeChanged);
        setLanguage(data.language);
        configManagerSetLanguage(data.language);
        MessageToast.success(
          t('UserProfile_Msg_UpdateSuccess', 'Update success', { type: 'msg', desc: '' }),
        );
        closeShellDialog();
      })
      .then(() => {
        fetchLanguages(data.language).then((result) => {
          setLanguages(result.data);
        });
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setData({
          ...originalData,
        });
        MessageToast.error(
          t('UserProfile_Msg_UpdateFailed', 'Update fail', { type: 'msg', desc: '' }),
        );
        setLoading(false);
      });
  };

  const handleCancel = () => {
    closeShellDialog();
    setData({
      ...originalData,
    });
  };

  useEffect(() => {
    setTimeFormatIndex(TimeFormatOptions.indexOf(data.timeFormat));
  }, [data.timeFormat]);

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      // return Promise.all([fetchMe(), fetchBasicSetup(), fetchLanguages(getCurrentLanguage())])
      return Promise.all([fetchMe(), fetchLanguages(getCurrentLanguage())])
        .then((results) => {
          const me = results[0].data;
          setLanguage(me.language);
          setThemeId(me.themeId);
          setData({
            ...me,
          });
          setOriginalData({
            ...me,
          });
          setLanguages(results[1].data || []);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  }, []);

  return (
    <div>
      <Title
        level={TitleLevel.H3}
        style={{
          padding: '16px 24px',
          fontSize: '16px',
        }}
      >
        {t('UserProfile_ProfileSettings', 'Settings', { type: 'tit', desc: '' })}
      </Title>
      <div style={{ borderBottom: borderStyle, borderTop: borderStyle }}>
        <div
          style={{
            display: 'flex',
            width: '636px',
            height: '100%',
            minHeight: '330px',
            padding: 0,
          }}
        >
          <List
            style={{ width: '219px' }}
            mode={ListMode.SingleSelect}
            noDataText={t('UserProfile_Msg_NoDataAvailable', 'No data available', {
              type: 'msg',
              desc: '',
            })}
            onItemClick={(evt: Ui5CustomEvent<ListDomRef, ListItemClickEventDetail>) => {
              setCurrentItem((evt.detail.item as HTMLElement).id);
            }}
          >
            <StandardListItem
              icon="palette"
              id={UserProfileAppearance}
              selected={currentItem === UserProfileAppearance}
              description={getThemeById(getThemeId())?.displayName}
            >
              {t('UserProfile_Appearance', 'Appearance', { type: 'mit', desc: '' })}
            </StandardListItem>
          </List>
          <section
            style={{
              flexGrow: 1,
              display: 'flex',
              borderLeft: borderStyle,
            }}
          >
            {currentItem === UserProfileLanguageAndRegion && (
              <LanguageAndRegion
                data={data}
                languages={languages}
                onDataPropChange={onDataPropChange}
              />
            )}
            {currentItem === UserProfileAppearance && (
              <UserAppearance data={data} theme={languages} onDataPropChange={onDataPropChange} />
            )}
          </section>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '60px',
        }}
      >
        <Button
          disabled={JSON.stringify(data) === JSON.stringify(originalData)}
          design={ButtonDesign.Emphasized}
          onClick={handleSave}
          data-testid="saveButton"
        >
          {t('UerProfile_Save', 'Save', { type: 'but', desc: '' })}
        </Button>
        <Button
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
          }}
          onClick={handleCancel}
          data-testid="cancelButton"
        >
          {t('UerProfile_Cancel', 'Cancel', { type: 'but', desc: '' })}
        </Button>
      </div>

      {isLoading && (
        <div
          style={{
            top: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            background: 'rgba(0, 0, 0, 0.01)',
            zIndex: 999,
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default UserProfile;
