import React, { useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import eurekaMgrs from '@eureka/ui-managers';
import eureka from 'eureka';
import {
  Button,
  ButtonDesign,
  Popover,
  ShellBar,
  StandardListItem,
  List,
} from '@ui5/webcomponents-react';
import { PopoverDomRef } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-icons/dist/copy';
import '@ui5/webcomponents-icons/dist/menu';
import '@ui5/webcomponents-icons/dist/menu2';
import '@ui5/webcomponents-icons/dist/product';
import '@ui5/webcomponents-icons/dist/log.js';
import '@ui5/webcomponents-icons/dist/user-settings.js';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';

import { RootState, showMenu, hideMenu } from 'src/common/redux';
import getAvatar from './Avatar';
import LogoutForm from './LogoutForm';
import UserProfile from './UserProfile';
// import Notification from './Notification';
import searchInfo from './searchInfo';
import logo from './logo';
import { MicroFrontendProps, ConfigJson } from 'src/types';

const { addConfig } = eurekaMgrs.ConfigManager;
const { getFragmentsWithTag, renderFragments, unmountFragments } = eurekaMgrs.FragmentManager;
const { eventBus } = eurekaMgrs;
const { useTranslation } = eureka.I18nProvider;

/* istanbul ignore next */
export const UserProfileFragment = ({
  config,
  history,
}: {
  config: ConfigJson;
  history: History;
}) => {
  const domRef = useRef(null);
  useEffect(() => {
    const container = domRef.current;
    const fragments = getFragmentsWithTag('shell-user_profile', config);
    let renderMap = null;
    if (container) {
      renderMap = renderFragments({
        container,
        fragments,
        renderFragment: (fragmentContainer, fragment) => {
          fragment.render(fragmentContainer, history, {
            config,
            eventBus,
          });
        },
      });
    }
    return () => {
      if (container) {
        unmountFragments({ container, renderMap });
      }
    };
  }, []);
  return (
    <div ref={domRef} className="user-profile-fragment" style={{ width: '100%', height: '100%' }}>
      <UserProfile config={config} eventBus={eventBus} />
    </div>
  );
};

export const handleSidenav = ({
  isMenuShow,
  dispatch,
}: {
  isMenuShow: boolean;
  dispatch: Dispatch;
}) => {
  if (isMenuShow) {
    dispatch(hideMenu());
  } else {
    dispatch(showMenu());
  }
  eventBus.emit('sidenav-show-hide', null, { collapsed: isMenuShow });
  addConfig('SidenavCollapsed', isMenuShow);
};

export const handleSearchInput = (searchInput: HTMLInputElement | null) => {
  const value = searchInput?.value;
  let suggestionItems = [];
  if (value) {
    suggestionItems = searchInfo.searchList.filter(
      (item: { text: string }) => item.text.toUpperCase().indexOf(value.toUpperCase()) === 0,
    );
  }
  // remove the previous suggestions
  [].slice.call(searchInput?.children).forEach((child) => {
    searchInput?.removeChild(child);
  });
  // add the new suggestions according to the  user input
  suggestionItems.forEach((item: { text: string }) => {
    const li = document.createElement('ui5-suggestion-item');
    // @ts-ignore
    li.text = item.text;
    searchInput?.appendChild(li);
  });
};

export const handleNotificationClick = ({
  event,
  notificationPopoverRef,
  profileMenuRef,
}: {
  event;
  notificationPopoverRef: React.RefObject<PopoverDomRef>;
  profileMenuRef: React.RefObject<PopoverDomRef>;
}) => {
  closeProfileMenu(profileMenuRef);
  if (notificationPopoverRef && notificationPopoverRef.current) {
    notificationPopoverRef.current.showAt(event.detail.targetRef);
  }
};

export const handleProfileClick = ({
  event,
  notificationPopoverRef,
  profileMenuRef,
}: {
  event;
  notificationPopoverRef: React.RefObject<PopoverDomRef>;
  profileMenuRef: React.RefObject<PopoverDomRef>;
}) => {
  notificationPopoverRef?.current?.close();
  profileMenuRef?.current?.showAt(event.detail.targetRef);
};

const openProfileSetting = (event: React.MouseEvent, config: ConfigJson, t: any) => {
  closeProfileMenu();
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-profile',
    data: {
      showDialog: true,
      title: t('Header_ProfileSettings', 'Settings', { type: 'tit', desc: '' }),
      content: () => {
        return <UserProfile config={config} eventBus={eventBus} />;
      },
    },
  });
};

const closeProfileMenu = (profileMenuRef?: React.RefObject<PopoverDomRef>) => {
  profileMenuRef?.current?.close && profileMenuRef.current.close();
};

const logout = () => {
  closeProfileMenu();
  eventBus.emit('dialog-show-hide', '', {
    type: 'user-logout',
    data: {
      showDialog: true,
      content: <LogoutForm eventBus={eventBus} />,
    },
  });
};

type Props = Pick<MicroFrontendProps, 'config' | 'settings' | 'user' | 'history'>;
export const Header: React.FC<Props> = ({ config, user, settings, history }) => {
  const notificationPopoverRef = useRef<PopoverDomRef>(null);
  const profileMenuRef = useRef<PopoverDomRef>(null);
  // const searchInputRef = useRef(null);
  const isMenuShow = useSelector((state: RootState) => state.common.isMenuShow);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    addConfig('SidenavCollapsed', !isMenuShow);
  }, [isMenuShow]);

  useEffect(() => {
    eventBus.on('wa-loaded', () => {
      addConfig('waLoaded', true);
    });
  }, []);

  return (
    <div
      data-testid="rpp.header"
      style={{
        background: `var(--sapShellColor)`,
      }}
    >
      <ShellBar
        data-testid={'rpp.header.shellBar'}
        id="eureka-shellbar"
        className="shellbar"
        logo={<img alt="SAP Logo" src={logo} />}
        primaryTitle={t('AppTitle')}
        profile={getAvatar(user)}
        startButton={
          <Button
            data-testid={'shellbarHamburgerButton'}
            className="shellbar-hamburger-button"
            icon={isMenuShow ? 'menu' : 'menu2'}
            design={ButtonDesign.Transparent}
            onClick={() => handleSidenav({ isMenuShow, dispatch })}
            data-ui5-stable="menu-toggle-button"
          />
        }
        onProfileClick={(event) =>
          handleProfileClick({ event, notificationPopoverRef, profileMenuRef })
        }
        // showNotifications
        // notificationsCount={notifications?.length > 99 ? '99+' : notifications?.length}
        // onNotificationsClick={(event) =>
        //   handleNotificationClick({ event, notificationPopoverRef, profileMenuRef })
        // }
        // searchField={
        //   <Input
        //     data-testid={'rpp.header.searchInput'}
        //     data-ui5-stable="search-input"
        //     ref={searchInputRef}
        //     type="Text"
        //     value={undefined}
        //     valueState="None"
        //     placeholder="Enter a term"
        //     showSuggestions
        //     onInput={() => handleSearchInput(searchInputRef.current)}
        //     // onSuggestionItemSelect={null}
        //   />
        // }
      ></ShellBar>

      {/* <Popover
        ref={notificationPopoverRef}
        allowTargetOverlap
        horizontalAlign="Right"
        placementType="Bottom"
        preventFocusRestore={false}
      >
        <Notification
          settings={settings}
          allNotifications={notifications}
          onDismissNotification={actions.dismissNotification}
          onDismissAllNotifications={actions.dismissAllNotifications}
        />
      </Popover> */}

      <Popover
        data-testid={'rpp.header.popover'}
        ref={profileMenuRef}
        allowTargetOverlap
        horizontalAlign="Right"
        placementType="Bottom"
        preventFocusRestore={false}
      >
        <List>
          <StandardListItem
            type="Active"
            icon={'user-settings'}
            selected={false}
            onClick={(evt) => openProfileSetting(evt, config, t)}
            style={{ margin: '3px' }}
            data-key="1"
            // infoState="None"
          >
            {t('ProfileSettings', 'Settings', { type: 'but', desc: '' })}
          </StandardListItem>
          <StandardListItem
            type="Active"
            icon="log"
            selected={false}
            onClick={logout}
            style={{ margin: '2px' }}
            data-key="2"
            // infoState="None"
          >
            {t('Header_Logout')}
          </StandardListItem>
        </List>
      </Popover>
    </div>
  );
};

export default Header;
