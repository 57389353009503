import React, { Suspense } from 'react';
import eureka from 'eureka';

import Header from 'src/features/components/Header';
import SidePanel from 'src/features/components/SidePanel';
import { ShellDialog } from 'src/common/plugins/dialog.plugin';
import { MicroFrontendProps } from 'src/types';
import logo from './ErrorBear';

const { Spinner } = eureka.components;
const { useTranslation } = eureka.I18nProvider;

const errorMapping = {
  500: {
    errorTitle: 'ErrorTips_Oops',
    errorContent: 'ErrorTips_500',
  },
  403: {
    errorTitle: 'ErrorTips_Wait',
    errorContent: 'ErrorTips_403',
  },
  404: {
    errorTitle: 'ErrorTips_Ohno',
    errorContent: 'ErrorTips_404',
  },
};

const styles = {
  errorPage: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorPageWrapper: {
    marginRight: '122px',
    height: '259px',
    textAlign: 'center',
  },
  errorTitle: {
    fontWeight: 'normal',
    fontSize: '144px',
    lineHeight: '166px',
    color: '#a7a7a7',
    marginBottom: '17px',
    marginTop: '-3rem',
  },
  errorContent: {
    fontSize: '25px',
    lineHeight: '29px',
    color: '#a7a7a7',
  },
  errorMsg: {
    fontSize: '42px',
    fontWeight: 'bold',
    lineHeight: '48px',
    color: '#fff',
    margin: 0,
  },
  errorMsgWrapper: {
    position: 'absolute',
    top: '225px',
    left: '145px',
    textAlign: 'center',
  },
};

type Props = {
  errorType: string;
  errorCode: string;
  props: Omit<MicroFrontendProps, 'name' | 'host'>;
};

const ErrorComponent: React.FC<Props> = (rowProps) => {
  const { props, errorType, errorCode } = rowProps;
  const { t } = useTranslation();
  const errorInstance = errorMapping[errorType];
  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div className="fd-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div>
            <Suspense fallback={<Spinner />}>
              <SidePanel history={props.history} match={props.match} config={props.config} />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <div style={styles.errorPage}>
              <div style={styles.errorPageWrapper}>
                <h1 style={styles.errorTitle}>{t(errorInstance.errorTitle)}</h1>
                <p style={styles.errorContent}>{t(errorInstance.errorContent)}</p>
                {errorType === '500' && <p style={styles.errorContent}>{t('ErrorTips_refresh')}</p>}
                <p style={styles.errorContent}>{t('ErrorTips_back')}</p>
                {errorType === '500' && (
                  <p style={{ ...styles.errorContent, marginTop: '43px', width: '654px' }}>
                    {t('ErrorCode_code')}
                    {errorCode}
                  </p>
                )}
              </div>
              <div className="img-wrapper" style={{ position: 'relative', height: '545px' }}>
                <img alt="Error Logo" src={logo} width={452} height={545} />
                <div style={styles.errorMsgWrapper}>
                  <p style={styles.errorMsg}>{errorType}</p>
                  <p style={{ fontSize: '20px', color: '#fff', fontWeight: 'bold', margin: 0 }}>
                    {t('ErrorCode_error')}
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ShellDialog key="Error-page-dialog" />
    </div>
  );
};

export default ErrorComponent;
