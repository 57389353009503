import React, { useEffect, useRef } from 'react';
import { Avatar } from '@ui5/webcomponents-react';
import { Ui5DomRef } from '@ui5/webcomponents-react';

export default (props) => {
  const avatarRef = useRef<Ui5DomRef>(null);
  useEffect(() => {
    let domRef = avatarRef.current;
    if (props.type === 'image' && domRef?.getDomRef) {
      const ref = domRef.getDomRef();
      if (ref?.children?.[0]) {
        (ref.children[0] as HTMLElement).style.backgroundImage = `url("${props.value}")`;
        (ref.children[0] as HTMLElement).style.backgroundColor = 'var(--ui5-avatar-accent6)';
      }
    }
    return () => {
      domRef = null;
    };
  }, []);

  return <Avatar ref={avatarRef} {...props} />;
};
