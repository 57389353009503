import { axiosCreate } from './eureka';
import { UserProfileState } from 'src/types';

export const usersAxios = axiosCreate({
  baseURL: '/api/user-configuration-plus/business/v1/users',
}).instance;

export const setupsAxios = axiosCreate({
  baseURL: '',
}).instance;

export const languagesAxios = axiosCreate({
  baseURL: '',
}).instance;

export const fetchMe = async () => usersAxios.get('/me');

export const patchMe = async (payload: UserProfileState | Record<string, unknown>) =>
  usersAxios.patch('/me', payload);

export const fetchBasicSetup = async () => setupsAxios.get('/basic-setup');

export const fetchLanguages = async (currentLang: string) => languagesAxios.get(`/${currentLang}`);
