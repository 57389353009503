import React, { Dispatch, SetStateAction } from 'react';
import { AxiosResponse } from 'axios';

export interface SidenavItem {
  icon: string;
  id: string;
  order: number;
  router: string;
  text: string;
  skipPermission: boolean;
  permissions?: string[];
  conjunction?: string;
}

export interface Sidenav {
  icon: string;
  id: string;
  order: number;
  router: string;
  skipPermission: boolean;
  text: string;
  items: SidenavItem[];
}

export interface Entrypoint {
  router: string;
  url: string;
}

export interface ConfigComponent {
  config: {
    app: string;
    routers: string[];
    sidenav?: Sidenav[];
    entrypoints?: Entrypoint[];
    fragments?: [{ name: string; section: string[] }];
  };
  location: string;
  name: string;
  port?: string;
  commit?: string;
}

export interface ConfigJson {
  'shell-ui': {
    location: string;
    name?: string;
    commit?: string;
    port?: string;
  };
  config: {
    cdn: string;
  };
  components: ConfigComponent[];
}

export interface UserInfo {
  auth_provider: string;
  email: string;
  exp: number;
  name: string;
  sid: string;
  sub: string;
  tenant_id: string;
}

export interface CSRFTokenInfo {
  token: string;
}

export interface User {
  avatarIcon: { type: string; value: string };
  creationTime: string;
  dateFormat: string;
  email: string;
  externalUserId: string;
  familyNameFirst: boolean;
  id: number;
  language: string;
  phone: string;
  pictureId: number;
  profileTimeZone: string;
  role: string;
  s4ExternalUserId: number;
  status: string;
  tenantId: number;
  timeFormat: string;
  timeZone: string;
  useCompanyTimeZone: boolean;
  userName: { firstName: string; lastName: string };
  userUpdateState: string;
  themeId?: string;
}

export interface FetchFunctions {
  fetchConfig: () => Promise<AxiosResponse<ConfigJson>>;
  fetchAuth: () => Promise<AxiosResponse<UserInfo>>;
  fetchCsrf: () => Promise<AxiosResponse<CSRFTokenInfo>>;
}

export interface Settings {
  basicSetup?: Record<string, any>;
  userProfile?: Record<string, any>;
  companyProfile?: Record<string, any>;
  timeZone?: string;
  timeFormat?: string;
  dateFormat?: string;
  id?: string;
}

export interface AppState {
  initializing: boolean;
  fetchConfigError: boolean;
  authUserError: boolean;
  config: ConfigJson;
  settings: Settings;
  user: UserInfo;
}

export interface MFE {
  name: string;
  host: string;
  routers: string[];
}

export type SetAppState = Dispatch<SetStateAction<AppState>>;
export type SetMFE = Dispatch<SetStateAction<MFE[] | []>>;
export type UserRef = React.MutableRefObject<UserInfo>;
export type SettingsRef = React.MutableRefObject<Settings>;

export const isPromiseFulfilledResult = (res): res is PromiseFulfilledResult<any> =>
  Reflect.has(res, 'status') && res.status === 'fulfilled' && Reflect.has(res, 'value');
export const isPromiseRejectedResult = (res): res is PromiseRejectedResult =>
  Reflect.has(res, 'status') && res.status === 'rejected' && Reflect.has(res, 'reason');
export const isSidenav = (obj): obj is Sidenav => Reflect.has(obj, 'items');
