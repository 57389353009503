import React, { useEffect } from 'react';
import {
  Select,
  Option,
  Label,
  SegmentedButton,
  ToggleButton,
  Ui5CustomEvent,
  SelectDomRef,
  SegmentedButtonDomRef,
} from '@ui5/webcomponents-react';
import { SelectChangeEventDetail } from '@ui5/webcomponents/dist/Select';
import { SegmentedButtonSelectionChangeEventDetail } from '@ui5/webcomponents/dist/SegmentedButton';
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
// import * as actions from '../redux/actions';
// import { fetchTimeZone } from '../redux/actions';

import { useTranslation } from './eureka';
import {
  DateFormatOptions,
  DateFormatTexts,
  TimeFormatOptions,
  TimeFormatTexts,
  TimeFormatTextsDefualts,
} from './options';
import { UserProfileState } from 'src/types';

const formItemStyle = {
  width: '100%',
  margin: '15px auto',
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  width: '100%',
  marginBottom: '4px',
};

interface languages {
  displayLanguageCode: string;
  language: string;
}

type Props = {
  data: UserProfileState;
  languages: languages[];
  onDataPropChange: (value: string, propName: string) => void;
};

// TODO:
const LanguageAndRegion: React.FC<Props> = ({ data, languages, onDataPropChange }) => {
  const { t } = useTranslation('shell');
  // const { timezone } = common;
  // const { fetchTimeZone } = actions;
  const timezone = [];

  // useEffect(() => {
  //   if (!timezone) {
  //     fetchTimeZone();
  //   }
  // }, []);

  return (
    <div style={{ padding: '10px 22px' }}>
      <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_Language', 'Language', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          id="language"
          data-testid="select-language"
          style={{ width: '100%' }}
          onChange={(evt: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>) => {
            const lang = evt.detail.selectedOption.dataset.id;
            lang && onDataPropChange(lang, 'language');
          }}
        >
          {languages.map((language: languages) => {
            return (
              <Option
                key={language.displayLanguageCode}
                data-id={language.displayLanguageCode}
                value={language.displayLanguageCode}
                selected={language.displayLanguageCode === data.language}
              >
                {language.language}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_DateFormat', 'Date Format', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          id="dateFormat"
          style={{ width: '100%' }}
          onChange={(evt: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>) => {
            const lang = evt.detail.selectedOption.dataset.id;
            lang && onDataPropChange(lang, 'dateFormat');
          }}
        >
          {DateFormatOptions.map((dateFormat, index) => {
            return (
              <Option
                key={dateFormat}
                data-id={dateFormat}
                value={dateFormat}
                selected={dateFormat === data.dateFormat}
              >
                {DateFormatTexts[index]}
              </Option>
            );
          })}
        </Select>
      </div>
      <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_TimeFormat', 'Time Format', { type: 'tit', desc: '' })}:
        </Label>
        <SegmentedButton
          onSelectionChange={(
            evt: Ui5CustomEvent<SegmentedButtonDomRef, SegmentedButtonSelectionChangeEventDetail>,
          ) => onDataPropChange(evt.detail.selectedItem.id, 'timeFormat')}
        >
          {TimeFormatOptions.map((timeFormat, index) => (
            <ToggleButton
              key={timeFormat}
              id={timeFormat}
              pressed={timeFormat === data.timeFormat}
              data-testid={`time-format-${timeFormat}`}
            >
              {t(TimeFormatTexts[index], TimeFormatTextsDefualts[index], {
                type: 'but',
                desc: '',
              })}
            </ToggleButton>
          ))}
        </SegmentedButton>
      </div>
      <div style={formItemStyle}>
        <Label style={labelStyle}>
          {t('UserProfile_TimeZone', 'Time Zone', { type: 'tit', desc: '' })}:
        </Label>
        <Select
          id="timeZone"
          style={{ width: '220px' }}
          onChange={(evt: Ui5CustomEvent<SelectDomRef, SelectChangeEventDetail>) => {
            const lang = evt.detail.selectedOption.dataset.id;
            lang && onDataPropChange(lang, 'profileTimeZone');
          }}
        >
          {timezone?.map((timeZone, index) => {
            return (
              <Option
                key={timeZone.timezone}
                data-id={timeZone.timezone}
                value={timeZone.timezone}
                selected={timeZone.timezone === data.profileTimeZone}
              >
                {timeZone.displayName}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default LanguageAndRegion;
