import React, { useEffect, useRef } from 'react';
import eurekaMgrs from '@eureka/ui-managers';
import {
  SideNavigation,
  SideNavigationDomRef,
  Ui5DomRef,
  Ui5CustomEvent,
} from '@ui5/webcomponents-react';
import UI5Element from '@ui5/webcomponents-base/dist/UI5Element.js';
import { SideNavigationSelectionChangeEventDetail } from '@ui5/webcomponents-fiori/dist/SideNavigation';
const { getThemeId, setThemeId } = eurekaMgrs.AppearanceManager;

export interface SideNavigationObserverProps {
  className: string;
  style: any;
  collapsed: boolean;
  selectedId: string;
  noIcons: boolean;
  onSelectionChange: (
    event: Ui5CustomEvent<SideNavigationDomRef, SideNavigationSelectionChangeEventDetail>,
  ) => void;
}
type Props = React.PropsWithChildren<SideNavigationObserverProps>;

let EventMap = {};

let TreeNodeTag = 'ui5-tree';
let ListNodeTag = 'ui5-list';
let LiTreeTag = 'ui5-li-tree';
let IconTag = 'ui5-icon';
if (process.env.NODE_ENV !== 'test') {
  TreeNodeTag = `ui5-tree-${process.env.APP_NAME}`;
  ListNodeTag = `ui5-list-${process.env.APP_NAME}`;
  LiTreeTag = `ui5-li-tree-${process.env.APP_NAME}`;
  IconTag = `ui5-icon-${process.env.APP_NAME}`;
}

const getTreeRoot = (currentDom: Ui5DomRef) =>
  currentDom?.shadowRoot?.querySelector(`.ui5-sn-root ${TreeNodeTag}`);

const attachEventsForAll = (
  currentDom: Ui5DomRef,
  clickHandler: (evt: React.MouseEvent) => void,
) => {
  const treeRoot = getTreeRoot(currentDom);
  const firstLevelItems = treeRoot?.shadowRoot?.querySelectorAll(`${ListNodeTag} > ${LiTreeTag}`);
  if (firstLevelItems?.length) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < firstLevelItems.length; i++) {
      const item = firstLevelItems[i] as UI5Element;
      if (item) {
        const { nodeName } = item;
        if (nodeName === LiTreeTag.toUpperCase()) {
          // eslint-disable-next-line no-underscore-dangle
          EventMap[item.__id as any] = {
            item,
            clickHandler,
          };
          (item as any).onclick = clickHandler;
        }
      }
    }
  }
};

const detachEventsForAll = () => {
  Object.keys(EventMap).forEach((key: string) => {
    const entry = EventMap[key];
    if (entry.item) {
      entry.item.onclick = null;
    }
  });
  EventMap = {};
};

const clickHandler = (evt: React.MouseEvent) => {
  const target: any = evt.target as UI5Element;
  if (
    target?.shadowRoot &&
    target?.icon &&
    target?.level === 1 &&
    !!target?.shadowRoot?.querySelector(`${IconTag}.ui5-li-tree-toggle-icon`)
  ) {
    const foldIcon = target.shadowRoot.querySelector(`${IconTag}.ui5-li-tree-toggle-icon`);
    if (foldIcon) {
      foldIcon.fireEvent('click');
    }
  }
};

const SideNavigationObserver: React.FC<Props> = React.forwardRef((props, ref) => {
  const item = useRef<Ui5DomRef>(null);
  const ui5TreeCallback = () => {
    detachEventsForAll();
    setTimeout(() => {
      if (item.current) {
        attachEventsForAll(item.current, clickHandler);
      }
    }, 1);
  };
  useEffect(() => {
    const currentDom = item.current;
    let observer;
    const timer = setTimeout(() => {
      if (currentDom?.shadowRoot) {
        const treeRoot = currentDom.shadowRoot.querySelector(`.ui5-sn-root ${TreeNodeTag}`);
        if (treeRoot) {
          const config = { attributes: true, childList: true, subtree: true };
          observer = new MutationObserver(ui5TreeCallback);
          observer.observe(treeRoot, config);
          attachEventsForAll(currentDom, clickHandler);
          // clear background
          const treeItems = treeRoot.querySelectorAll('ui5-tree-item-rgp-shell');
          treeItems.forEach((item) => {
            if (item?.shadowRoot?.querySelector('li')?.style) {
              const li = item.shadowRoot.querySelector('li') as any;
              if (li.style.background) {
                li.style.background = 'none';
              }
            }
          });
          const allItems = treeRoot.querySelectorAll('ui5-tree-item-rgp-shell[selected]');
          const isMorning = getThemeId() === 'sap_horizon';
          allItems.forEach((item) => {
            if (item?.shadowRoot?.querySelector('li')?.style && isMorning) {
              const li = item.shadowRoot.querySelector('li') as any;
              li.style.background = '#D1EFFF';
            }
          });
        }
      }
    }, 0);
    return () => {
      detachEventsForAll();
      observer?.disconnect();
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    const currentDom = item.current;
    if (currentDom?.shadowRoot) {
      const snRoot = currentDom.shadowRoot.querySelector('.ui5-sn-root') as HTMLDivElement;
      if (snRoot) {
        snRoot.style.padding = '0 0.75rem';
      }
    }
  }, [item]);

  return <SideNavigation ref={item} {...props} />;
});

export default SideNavigationObserver;
