import React from 'react';
import { AvatarShape, AvatarSize } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-icons/dist/employee.js';
import MyAvatar from './Avatar';
import { UserInfo } from 'src/types';

const getAvatar = (user: UserInfo) => {
  let avatar;
  let type;
  let value;
  if (user && 'avatarIcon' in user) {
    avatar = user.avatarIcon;
    type = avatar.type?.toLocaleLowerCase();
    value = avatar;
  }
  return (
    <MyAvatar
      initials={type === 'initials' ? value : ''}
      image={type === 'image' ? value : null}
      icon={type === 'genderneutralicon' ? 'employee' : ''}
      shape={AvatarShape.Circle}
      size={AvatarSize.XS}
      type={type}
      value={value}
    />
  );
};

export default getAvatar;
