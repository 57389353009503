import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import {
  SideNavigationItem,
  SideNavigationSubItem,
  Ui5CustomEvent,
  SideNavigationDomRef,
} from '@ui5/webcomponents-react';
import { SideNavigationSelectionChangeEventDetail } from '@ui5/webcomponents-fiori/dist/SideNavigation';
import '@ui5/webcomponents-icons/dist/activity-individual';
import '@ui5/webcomponents-icons/dist/documents';
import '@ui5/webcomponents-icons/dist/home';
import '@ui5/webcomponents-icons/dist/json-imports/Icons.js';
import '@ui5/webcomponents-icons/dist/kpi-corporate-performance';
import '@ui5/webcomponents-icons/dist/settings';
import '@ui5/webcomponents-icons/dist/visits';
import '@ui5/webcomponents-icons/dist/user-settings';
import { createUseStyles } from 'react-jss';

import { RootState } from 'src/common/redux';
import { getURLParam } from 'src/common/Utils';
import { ConfigJson, ConfigComponent, Sidenav, SidenavItem, MicroFrontendProps } from 'src/types';
import SideNavigationObserver from './SideNavigationObserver';
// import OverrideSidePanel from './OverrideSidePanel';
// import OverrideShadowDOMStyle from './OverrideShadowDOMStyle';

// react-jss style setup
const disabledColor = 'rgba(205, 206, 207, 0.8)';
const seletedColor = '#0854a0';
const enableColor = '#32363a';
const styles = {
  sideNavigation: {
    overflowY: 'auto',
    '& > div': {
      overflow: 'hidden',
    },
    '--_ui5_list_item_title_size': 'var(--sapFontSize)',
  },
  disabled: {
    color: disabledColor,
    backgroundColor: 'transparent !important',
    borderLeft: '4px solid transparent',
    cursor: 'initial',
    '& ui5-icon': {
      color: disabledColor,
    },
    '& span': {
      color: disabledColor,
    },
  },
  enabled: {
    borderLeft: '4px solid transparent',
    // backgroundColor: '#e5f0fa',
    '& ui5-icon': {
      color: enableColor,
    },
    '&[selected=true]': {
      borderLeft: `4px solid ${seletedColor}`,
      '& ui5-icon': {
        color: seletedColor,
      },
      '& span': {
        color: seletedColor,
      },
    },
  },
  customized: {
    '& >span:nth-child(3)': {
      position: 'absolute',
      right: 0,
      height: '48px',
      width: '240px',
      boxSizing: 'border-box',
      '& ui5-icon': {
        right: '13px',
        top: '16px',
        position: 'absolute',
      },
    },
  },
};

const useStyles = createUseStyles(styles);
const { getConfig } = eurekaMgrs.ConfigManager;
const { useTranslation } = eureka.I18nProvider;
const homeDisabled = true;
const SideNaviRelationCached = new Map();

const isDisable = (item: unknown) =>
  item &&
  typeof item === 'object' &&
  Reflect.has(item, 'disabled') &&
  Reflect.get(item, 'disabled');

const walkSidenav = ({
  propName,
  propValue,
  valueName,
  isDisable,
  comp,
  sideNav,
}: {
  propName: string;
  propValue: string;
  valueName: string;
  isDisable: (item: unknown) => boolean;
  comp: ConfigComponent;
  sideNav: number;
}) => {
  const m = sideNav;
  const nav = comp?.config?.sidenav?.[m];
  if (nav?.[propName] && nav[propName] === propValue) {
    return {
      appName: comp.config.app,
      [valueName]: nav[valueName],
      disabled: isDisable(nav),
    };
  } else if (nav?.items && Array.isArray(nav.items) && nav.items.length) {
    for (let n = 0; n < nav.items.length; n++) {
      const item = nav.items[n];
      if (item[propName] && item[propName] === propValue) {
        return {
          appName: comp.config.app,
          [valueName]: item[valueName],
          disabled: isDisable(item),
        };
      }
    }
  } else if (
    comp.config.routers.includes(propValue) &&
    !['/kpi-library', '/next-best-actions-config'].includes(propValue)
  ) {
    const sidenav = comp?.config?.sidenav;
    let foundId = '';
    if (sidenav && sidenav.length > 0) {
      const res = sidenav.find((item) => item[propName] === propValue);
      res && (foundId = res[valueName]);
    }
    return {
      appName: comp.config.app,
      [valueName]: foundId || nav?.[valueName],
      disabled: isDisable(nav),
    };
  }
};

const getConfigValueByProp = (
  config: ConfigJson,
  propName: string,
  propValue: string,
  valueName: string,
) => {
  const valueKey = `propName-${propName}-propValue-${propValue}-valueName-${valueName}`;
  const retValue = SideNaviRelationCached.get(valueKey);
  if (retValue) {
    // get from cache if already stored
    return retValue;
  }
  for (let i = 0; i < config?.components?.length; i++) {
    const comp = config?.components[i];
    if (comp?.config?.sidenav?.length) {
      for (let m = 0; m < comp.config.sidenav.length; m++) {
        const retValue = walkSidenav({
          propName,
          propValue,
          valueName,
          isDisable,
          comp,
          sideNav: m,
        });
        if (retValue) {
          // store value and cache it
          SideNaviRelationCached.set(valueKey, retValue);
          return retValue;
        }
      }
    }
  }
  return null;
};

const checkPermissions = (sideNav: SidenavItem, currentUserPermissions: string[]) => {
  // eslint-disable-next-line no-prototype-builtins
  if (sideNav.hasOwnProperty('skipPermission') && sideNav.skipPermission) {
    return true;
  }
  if (sideNav.permissions) {
    const permissions = Array.isArray(sideNav.permissions)
      ? sideNav.permissions.map((p) => p.toUpperCase())
      : [];
    if (sideNav.conjunction && sideNav.conjunction === 'OR') {
      return permissions.length > 0 && permissions.some((p) => currentUserPermissions.includes(p));
    }
    return permissions.length > 0 && permissions.every((p) => currentUserPermissions.includes(p));
  }
  return false;
};

type Props = Pick<MicroFrontendProps, 'history' | 'config' | 'match'>;

export const SidePanel: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState('home');
  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);
  const isMenuShow = useSelector((state: RootState) => state.common.isMenuShow);

  const onNavigationItemClick = (e) => {
    const selected = e.target.selected;
    if (!selected) {
      return;
    }
    handleSelectionChange(e.target.id);
  };

  const onSelectionChange = (
    evt: Ui5CustomEvent<SideNavigationDomRef, SideNavigationSelectionChangeEventDetail>,
  ) => {
    const clickItemId = (evt.detail.item as HTMLElement).id;
    handleSelectionChange(clickItemId);
  };

  /* istanbul ignore next */
  const handleSelectionChange = (clickItemId: string) => {
    const { config } = props;
    if (!clickItemId) {
      return;
    }
    const testingLngCode = getURLParam(props.history.location.search, 'sap-language');
    const testingLngParam = testingLngCode ? `?sap-language=${testingLngCode}` : '';
    if (clickItemId === 'home' && !homeDisabled) {
      setSelectedId('home');
      props.history.push(`/${testingLngParam}`);
    } else {
      const cfg = getConfigValueByProp(config, 'id', clickItemId, 'router');
      if (cfg && cfg.router && cfg.router !== '#' && !cfg.disabled) {
        try {
          props.history.push(cfg.router + testingLngParam);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const getSideNavList = () => {
    const { config } = props;
    let sideNavList: Sidenav[] = [];
    if (config && config.components) {
      config.components.forEach((comp) => {
        const { sidenav } = comp.config;
        if (sidenav && Array.isArray(sidenav) && sidenav.length > 0) {
          sideNavList = [...sideNavList, ...sidenav];
        }
      });
    }
    sideNavList = sideNavList
      .filter(
        (i) =>
          i.items?.filter((c) => checkPermissions(c, currentUserPermissions)).length > 0 ||
          checkPermissions(i, currentUserPermissions),
      )
      .sort((a, b) => a.order - b.order);
    return sideNavList;
  };

  const renderNavItems = () =>
    getSideNavList().map((sideNav: Sidenav) => {
      if (sideNav.items && Array.isArray(sideNav.items) && sideNav.items.length) {
        return (
          <SideNavigationItem
            onClick={onNavigationItemClick}
            key={sideNav.id}
            id={sideNav.id}
            className={
              isDisable(sideNav) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            text={t(sideNav.text)}
            icon={sideNav.icon}
            title={t(sideNav.text)}
            expanded={!!sideNav.items.find((child) => child.id === selectedId)}
          >
            {sideNav.items
              .filter((sideNavItem) => checkPermissions(sideNavItem, currentUserPermissions))
              .map((sideNavItem) => (
                <SideNavigationSubItem
                  data-id={sideNavItem.id}
                  key={sideNavItem.id}
                  className={
                    isDisable(sideNavItem)
                      ? classes.disabled
                      : `${classes.enabled} ${classes.customized}`
                  }
                  id={sideNavItem.id}
                  text={t(sideNavItem.text)}
                  icon={sideNavItem.icon}
                  title={t(sideNavItem.text)}
                  selected={sideNavItem.id === selectedId}
                  onClick={onNavigationItemClick}
                />
              ))}
          </SideNavigationItem>
        );
      } else {
        return (
          <SideNavigationItem
            key={sideNav.id}
            className={
              isDisable(sideNav) ? classes.disabled : `${classes.enabled} ${classes.customized}`
            }
            id={sideNav.id}
            text={t(sideNav.text)}
            icon={sideNav.icon}
            selected={sideNav.id === selectedId}
            title={t(sideNav.text)}
            onClick={onNavigationItemClick}
          />
        );
      }
    });

  useEffect(() => {
    const { config } = props;
    const { pathname } = props.history.location;
    const matchPatch = props.match.path;
    // if (pathname !== matchPatch) {
    //   pathname = matchPatch;
    // }
    if (pathname !== '/') {
      const cfg = getConfigValueByProp(config, 'router', pathname, 'id');
      if (cfg?.id) {
        setSelectedId(cfg.id);
      } else {
        setSelectedId('home');
      }
    }
  }, [props]);

  useEffect(() => {
    const permissions = getConfig('CurrentUserPermissions') || [];
    setCurrentUserPermissions(permissions);
  }, []);

  return (
    <SideNavigationObserver
      className={classes.sideNavigation}
      style={{ height: '100%', fontSize: '14px' }}
      collapsed={!isMenuShow}
      selectedId={selectedId}
      noIcons={false}
      onSelectionChange={onSelectionChange}
      // footerItems={[
      //   <SideNavigationItem id="footer1" text="Legal Information" icon="compare" />,
      //   <SideNavigationItem id="footer2" text="Useful Links" icon="chain-link" />,
      // ]}
    >
      {renderNavItems()}
    </SideNavigationObserver>
  );
};

export default SidePanel;
