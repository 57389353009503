import React from 'react';
import CacheRoute from 'react-router-cache-route';
// import { Route } from 'react-router-dom';
import eureka from 'eureka';
import eurekaMgrs from '@eureka/ui-managers';
import { History } from 'history';
import { setTheme as setUi5Theme } from '@ui5/webcomponents-base/dist/config/Theme.js';
import { setLanguage as setUi5Language } from '@ui5/webcomponents-base/dist/config/Language.js';

import { ConfigJson, Settings, UserRef, AppState, SetAppState } from './types';

const children: JSX.Element[] = []; // children component list
const { eventBus } = eurekaMgrs;

export function renderRouteConfigV3(
  routes,
  contextPath: string,
  config: ConfigJson,
  settings: Settings,
  user: UserRef,
) {
  // Resolve route config object in React Router v3.
  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(
        item.childRoutes,
        newContextPath,
        config,
        settings,
        user,
      );
      children.push(
        <CacheRoute
          key={newContextPath}
          render={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            >
              {childRoutes}
            </item.component>
          )}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <CacheRoute
          key={newContextPath}
          component={(props) => (
            <item.component
              {...props}
              config={config}
              settings={settings}
              user={user}
              eventBus={eventBus}
            />
          )}
          path={newContextPath}
          exact
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };
  routes.forEach((item) => renderRoute(item, contextPath));
  // Use Switch so that only the first matched route is rendered.
  return children;
}

// TODO:
export const listenToEventBus = ({
  // rawSettings,
  state,
  setState,
  // history,
}: {
  state: AppState;
  setState: SetAppState;
  // history: History;
}) => {
  const onConfigurationUpdate = (message) => {
    console.log('message', message);
  };
  const onI18nUpdate = (langCode) => {
    eureka.I18nProvider.setLanguage(langCode);
    setUi5Language(eureka.I18nProvider.getUi5Language(langCode));
  };
  const onAppearanceUpdate = (settings) => {
    console.log(`Shell UI: appearance settings is ${JSON.stringify(settings)}`);
    const { themeChanged, themeId } = settings;
    if (themeChanged) {
      eurekaMgrs.AppearanceManager.setThemeId(themeId);
      setUi5Theme(eurekaMgrs.AppearanceManager.getThemeId());
    }
  };
  // const onNavigate = (path, newState) => {
  //   if (path) {
  //     history.push(path, newState);
  //   }
  // };

  eventBus.on('configuration-updated', onConfigurationUpdate);
  eventBus.on('i18n-update', onI18nUpdate);
  eventBus.on('appearance-update', onAppearanceUpdate);
  // eventBus.on('navigate-page', onNavigate);

  return () => {
    eventBus.detach('configuration-updated');
    eventBus.detach('i18n-update');
    eventBus.detach('appearance-update');
    // eventBus.detach('navigate-page');
  };
};
