import React, { Suspense } from 'react';
import eureka from 'eureka';

import { ShellDialog } from 'src/common/plugins/dialog.plugin';
import MessageToast from 'src/common/plugins/toast.plugin';
import { LayoutProps } from 'src/types';

const { Spinner } = eureka.components;

type Props = React.PropsWithChildren<LayoutProps>;

export const MashupLayout: React.FC<Props> = (props) => {
  return (
    <div className="fd-shell fd-shell--fundamentals">
      <div
        style={{
          minHeight: '100vh',
          height: '100vh',
        }}
      >
        <div className="fd-app" style={{ height: '100vh' }}>
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              {props.children}
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="default-layout-dialog" />
      <MessageToast key="default-message-toast" />
    </div>
  );
};

export default MashupLayout;
