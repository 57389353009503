import eurekaMgrs from '@eureka/ui-managers';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Dialog, DialogDomRef } from '@ui5/webcomponents-react';

const { eventBus } = eurekaMgrs;

type DialogAction = {
  type: 'cancel';
  data: {
    showDialog: boolean;
    content?: any;
  };
};

export const ShellDialog = () => {
  const dialogRef = useRef<DialogDomRef>(null);
  const [properties, setProperties] = useState({
    showDialog: false,
    content: '',
    hideHeader: true,
    footer: null,
    stretch: false,
    title: '',
    clearPadding: true,
  });

  const eventHandler = (action: DialogAction) => {
    const { data } = action;
    if (data) {
      const localData = { ...data };
      localData.showDialog = data.showDialog;
      if (data.content) {
        localData.content = typeof data.content === 'function' ? data.content() : data.content;
      }
      setProperties({
        ...properties,
        ...localData,
      });
      if (localData.showDialog) {
        dialogRef?.current?.show();
      } else {
        dialogRef?.current?.close();
      }
    }
  };

  const onAfterClose = useCallback(() => {
    setProperties({
      ...properties,
      showDialog: false,
    });
    dialogRef.current?.close();
  }, []);

  useEffect(() => {
    eventBus.on('dialog-show-hide', eventHandler);
    let timer;
    if (properties.clearPadding) {
      timer = setTimeout(() => {
        const container =
          dialogRef.current?.getDomRef &&
          (dialogRef.current.getDomRef()?.querySelector('div.ui5-popup-scroll') as HTMLElement);
        container && (container.style.padding = '0');
      }, 0);
    }
    return () => {
      clearTimeout(timer);
      eventBus.detach('dialog-show-hide');
    };
  }, [dialogRef, properties]);

  return (
    <Dialog
      ref={dialogRef}
      className="logout-modal"
      open={properties.showDialog}
      stretch={properties.stretch}
      title={properties.title}
      footer={properties.footer}
      // TODO:
      onAfterClose={onAfterClose}
    >
      {properties.content}
    </Dialog>
  );
};

/* istanbul ignore next */
export const closeShellDialog = () => {
  eventBus.emit('dialog-show-hide', '', {
    type: 'cancel',
    data: {
      showDialog: false,
    },
  });
};
