import axios from 'axios';
import { ConfigJson, UserInfo, CSRFTokenInfo } from '../types';

export const fetchFunctions = {
  fetchConfig() {
    return axios.get<ConfigJson>('/config.json');
  },
  fetchAuth() {
    return axios.get<UserInfo>('/auth/user');
  },
  fetchCsrf() {
    return axios.request<CSRFTokenInfo>({
      headers: {
        mode: 'cors',
        cache: 'no-cache',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
      },
      url: `/auth/csrf-token`,
      method: 'POST',
      withCredentials: true,
    });
  },
};

export default () => {
  return fetchFunctions;
};
