import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import eureka from 'eureka';

import { RootState } from 'src/common/redux';
import { ShellDialog } from 'src/common/plugins/dialog.plugin';
import MessageToast from 'src/common/plugins/toast.plugin';
import { LayoutProps } from 'src/types';
import Header from '../Header';
import SidePanel from '../SidePanel';

const { Spinner } = eureka.components;

type Props = React.PropsWithChildren<LayoutProps>;

export const DefaultLayout: React.FC<Props> = (props) => {
  const isMenuShow = useSelector((state: RootState) => state.common.isMenuShow);

  return (
    <div id="eureka-shell-container" className="fd-shell fd-shell--fundamentals">
      <div className="default-shell__header">
        <Suspense fallback={<Spinner />}>
          <Header
            history={props.history}
            config={props.config}
            settings={props.settings}
            user={props.user}
          />
        </Suspense>
      </div>
      <div className="fd-shell__app">
        <div className="fd-app">
          <div
            className={`fd-app__navigation fd-app__navigation--vertical${
              isMenuShow ? ' show-menu ' : ' hide-menu '
            }`}
          >
            <Suspense fallback={<Spinner />}>
              <SidePanel history={props.history} match={props.match} config={props.config} />
            </Suspense>
          </div>
          <main className="fd-app__main">
            <Suspense fallback={<Spinner cssClass="page-load-spinner" />}>
              {props.children}
            </Suspense>
          </main>
        </div>
      </div>
      <ShellDialog key="default-layout-dialog" />
      <MessageToast key="default-message-toast" />
    </div>
  );
};

export default DefaultLayout;
