import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface CommonState {
  isDxp: boolean;
  isMenuShow: boolean;
}

const initialState: CommonState = {
  isDxp: false,
  isMenuShow: true,
};

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    showMenu(state) {
      state.isMenuShow = true;
    },
    hideMenu(state) {
      state.isMenuShow = false;
    },
    setIsDxp(state, action: PayloadAction<boolean>) {
      state.isDxp = action.payload;
    },
  },
});

export const { showMenu, hideMenu, setIsDxp } = commonSlice.actions;

export default commonSlice.reducer;
